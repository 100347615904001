<template>
    <div :style="`width:${width};height:${height};`">
        <iframe :src="iframeUrl" frameborder="0" width="100%" height="100%" allowtransparency></iframe>
    </div>
</template>

<script>
import { _st } from '@/softech';

export default {
    props: {
        type        : { type: String, default: 'dashboard' },       // dashboard or question

        width       : { type: String, default: '100%' },
        height      : { type: String, default: '100%' },

        resource    : { type: String, default: '' },
        params      : { type: Object, default() { return {}; } },
        expire      : { type: [String, Number], default: null },    // token life in minutes

        bordered    : { type: Boolean, default: false },            // show border
        titled      : { type: Boolean, default: false },            // show title
        theme       : { type: String, default: '' },                // '' = light, 'night' = dark, 'transparent' = transparent
    },
    data: () => ({
        mbURL       : 'https://rpt.l5a.com',
        mbSecretKey : '81824ea76b49e574610a7e615d276d5b33394f7721c67b690ac6f0a67b15b89d',

        token       : '',
        iframeUrl   : '',
    }),
    mounted() {
        if( this.token == '' )
            this.generateToken();

        if( _st.isNUE( this.token ) )
            return;

        let theme = '';
        if( this.theme != '' )
            theme = `theme=${this.theme}&`;

        this.iframeUrl = `${this.mbURL}/embed/${this.type}/${this.token}#${theme}bordered=${this.bordered ? 'true' : 'false'}&titled=${this.titled ? 'true' : 'false'}`;
    },
    methods: {
        generateToken() {
            var jwt = require("jsonwebtoken");

            var payload = {
                resource: JSON.parse( `{ "${this.type}": ${this.resource} }` ),
                params: this.params
            };

            if( this.expire != null )
                payload.exp = Math.round( Date.now() / 1000 ) + ( parseInt( this.expire ) * 60 );

            this.token = jwt.sign(payload, this.mbSecretKey);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>